import { StoreTemplate } from "@/templates/TemplateLoader";
import { FormattedDataType } from "@/templates/types";
import React from "react";

interface RenderCollectionCardProps {
  collection: FormattedDataType["collections"][0];
  section: Partial<FormattedDataType>;
  isSliderSection?: boolean;
}

const RenderCollectionCard: React.FC<RenderCollectionCardProps> = ({
  collection,
  section,
  isSliderSection,
}) => {
  const Template = StoreTemplate.get();
  return (
    <Template.SectionCardWrapper
      isSlider={isSliderSection}
      data-test="collection-card"
      background={section?.background}
      isCollection
    >
      <Template.CollectionCard
        collection={collection}
        hideTitle={!section?.showItemName}
      />
    </Template.SectionCardWrapper>
  );
};

export default RenderCollectionCard;
