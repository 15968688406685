import { StoreTemplate } from "@/templates/TemplateLoader";
import { FormattedDataType } from "@/templates/types";
import React from "react";

interface RenderAttributeCardProps {
  attributeValue: FormattedDataType["attributeValues"][0];
  section: Partial<FormattedDataType>;
  isSliderSection?: boolean;
}

const RenderAttributeCard: React.FC<RenderAttributeCardProps> = ({
  attributeValue,
  section,
  isSliderSection,
}) => {
  const Template = StoreTemplate.get();
  return (
    <Template.SectionCardWrapper
      isSlider={isSliderSection}
      data-test="attributeValue-card"
      background={section?.background}
    >
      <Template.AttributeValueCard
        attributeValue={attributeValue}
        hideTitle={!section?.showItemName}
      />
    </Template.SectionCardWrapper>
  );
};

export default RenderAttributeCard;
