import { StoreTemplate } from "@/templates/TemplateLoader";
import { FormattedDataType } from "@/templates/types";
import React from "react";

interface RenderBannerCardProps {
  banner: FormattedDataType["banners"][0];
  section: Partial<FormattedDataType>;
  isSliderSection?: boolean;
}

const RenderBannerCard: React.FC<RenderBannerCardProps> = ({
  banner,
  section,
  isSliderSection,
}) => {
  const Template = StoreTemplate.get();
  return (
    <Template.SectionCardWrapper
      isSlider={isSliderSection}
      data-test="banner-card"
      background={section?.background}
    >
      <Template.BannerCard banner={banner} />
    </Template.SectionCardWrapper>
  );
};

export default RenderBannerCard;
